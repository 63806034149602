export const translations = {
  EN: {
    // BestRentalExperience translations
    bestRentalTitle: "Best rental prices for your rental experience in Skiathos",
    bestRentalText1: "At Smart Ride, we believe in making scooter rental easy and affordable. Our rental rates are competitive, and we offer flexible rental options to suit your individual needs. Whether you need a scooter for a day, a week, or even a month, we have you covered.",
    bestRentalText2: "We understand that planning a trip can be stressful, which is why we strive to make the rental process as easy as possible. You can reserve your scooter rental online, and our team is available to answer any questions you may have.",
    premiumRentalServices: "Premium Rental Services",
    servicesList: [
      "Unlimited KMs",
      "Third Party Insurance",
      "24/7 Support",
      "Tips & Hints for your ride in Skiathos",
      "Additional Driver is 3€ per day",
    ],
    conditions: "Conditions",
    conditionsList: [
      "Minimum Age 21 Years Old",
      "Valid Driving Licence Must Be For At Least 2 Years",
    ],
  },
  GR: {
    bestRentalTitle: "Καλύτερες τιμές ενοικίασης για την εμπειρία σας στη Σκιάθο",
    bestRentalText1: "Στην Smart Ride, πιστεύουμε ότι η ενοικίαση σκούτερ πρέπει να είναι εύκολη και προσιτή. Οι τιμές μας είναι ανταγωνιστικές και προσφέρουμε ευέλικτες επιλογές ενοικίασης για να καλύψουμε τις ανάγκες σας. Είτε χρειάζεστε σκούτερ για μία μέρα, μία εβδομάδα ή ακόμα και ένα μήνα, σας καλύπτουμε.",
    bestRentalText2: "Κατανοούμε ότι ο προγραμματισμός ενός ταξιδιού μπορεί να είναι αγχωτικός, γι' αυτό προσπαθούμε να κάνουμε τη διαδικασία ενοικίασης όσο το δυνατόν πιο εύκολη. Μπορείτε να κάνετε κράτηση του σκούτερ σας online, και η ομάδα μας είναι διαθέσιμη για να απαντήσει σε οποιεσδήποτε ερωτήσεις.",
    premiumRentalServices: "Υπηρεσίες Ενοικίασης Premium",
    servicesList: [
      "Απεριόριστα Χλμ",
      "Ασφάλιση Τρίτου",
      "Υποστήριξη 24/7",
      "Συμβουλές για την οδήγησή σας στη Σκιάθο",
      "Πρόσθετος Οδηγός είναι 3€ ανά ημέρα",
    ],
    conditions: "Προϋποθέσεις",
    conditionsList: [
      "Ελάχιστη Ηλικία 21 Ετών",
      "Έγκυρο Δίπλωμα Οδήγησης τουλάχιστον για 1 χρόνο",
    ],
  },
  IT: {
    bestRentalTitle: "Le migliori tariffe per la tua esperienza di noleggio a Skiathos",
    bestRentalText1: "Alla Smart Ride, crediamo che il noleggio di scooter debba essere facile e conveniente. Le nostre tariffe sono competitive e offriamo opzioni di noleggio flessibili per soddisfare le tue esigenze. Che tu abbia bisogno di uno scooter per un giorno, una settimana o anche un mese, abbiamo ciò che ti serve.",
    bestRentalText2: "Capisco che pianificare un viaggio possa essere stressante, ecco perché cerchiamo di rendere il processo di noleggio il più semplice possibile. Puoi prenotare il tuo scooter online e il nostro team è disponibile per rispondere a qualsiasi domanda.",
    premiumRentalServices: "Servizi di Noleggio Premium",
    servicesList: [
      "Chilometri illimitati",
      "Assicurazione di terze parti",
      "Supporto 24/7",
      "Suggerimenti per il tuo giro a Skiathos",
      "Pilota aggiuntivo è 3€ al giorno",
    ],
    conditions: "Condizioni",
    conditionsList: [
      "Età minima 21 anni",
      "Patente valida da almeno 2 anni",
    ],
  },
  GER: {
    bestRentalTitle: "Beste Mietpreise für Ihre Mieterfahrung auf Skiathos",
    bestRentalText1: "Bei Smart Ride glauben wir daran, dass das Mieten von Rollern einfach und erschwinglich sein sollte. Unsere Mietpreise sind wettbewerbsfähig und wir bieten flexible Mietoptionen, um Ihre individuellen Bedürfnisse zu erfüllen. Egal, ob Sie einen Roller für einen Tag, eine Woche oder sogar einen Monat benötigen, wir haben das Richtige für Sie.",
    bestRentalText2: "Wir wissen, dass die Planung einer Reise stressig sein kann, deshalb bemühen wir uns, den Mietvorgang so einfach wie möglich zu gestalten. Sie können Ihren Roller online reservieren, und unser Team steht Ihnen für alle Fragen zur Verfügung.",
    premiumRentalServices: "Premium-Mietdienste",
    servicesList: [
      "Unbegrenzte Kilometer",
      "Haftpflichtversicherung",
      "24/7 Support",
      "Tipps und Ratschläge für Ihre Fahrt auf Skiathos",
      "Zusätzlicher Fahrer kostet 3€ pro Tag",
    ],
    conditions: "Bedingungen",
    conditionsList: [
      "Mindestalter 21 Jahre",
      "Gültiger Führerschein seit mindestens 2 Jahren",
    ],
  },
};
