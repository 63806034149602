import sym125 from "../../../../images/home/SYM 125.jpg"
import sym200 from "../../../../images/home/sym200.jpg"
import atv450 from "../../../../images/home/ATV 450.jpg"
import atv450long from "../../../../images/home/ATV 450 LONG.jpg"

export const scooterItems = [
  {
    id: 1,
    img: sym125,
    title: "Sym sr 125cc"
  },
  {
    id: 2,
    img: sym200,
    title: "Sym st 200cc"
  },
  {
    id: 3,
    img: atv450,
    title: "Cforce atv 450cc"
  },
  {
    id: 4,
    img: atv450long,
    title: "Cforce atv 450cc long"
  }
]