import React from 'react';
import './OurStorySection.css';  
import { useLanguage } from '../../../../contexts/LanguageContext'; // Import LanguageContext
import { translations } from '../translations'; // Import translations

import img1 from "../../../../images/aboutimages/1.jpg";
import img2 from "../../../../images/aboutimages/2.webp";
import img3 from "../../../../images/aboutimages/3.jpg";
import img4 from "../../../../images/aboutimages/4.jpg";


const OurStorySection = () => {
  const { language } = useLanguage(); // Get the current language
  const content = translations[language]; // Get the translated content

  return (
    <div className="about-section">
      <div className="images-container">
        <div className="image-grid">
          <img src={img1} alt="Scooter on beach" className="grid-image image1" />
          <img src={img2} alt="Couple on scooters" className="grid-image image2" />
          <img src={img3} alt="Bikers at sunset" className="grid-image image3" />
          <img src={img4} alt="Quad on a trail" className="grid-image image4" />
        </div>
      </div>
      <div className="text-container">
        <h3>{content.ourShortStory}</h3>
        <h2>{content.professionalsDedicated}</h2>
        <p>{content.storyText1}</p>
        <p>{content.storyText2}</p>
      </div>
    </div>
  );
};

export default OurStorySection;
