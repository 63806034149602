export const translations = {
  EN: {
    // Home Page
    heroTitle: "Discover the Best Scooter Rentals",
    heroDescription: "Rent a scooter and explore the island with ease and style.",
    heroRentNow: "Rent Now",
    
    howItWorksTitle: "How It Works",
    howItWorksSteps: [
      { title: "Choose a Scooter", description: "Browse our selection of scooters and choose the one that suits you." },
      { title: "Book Online", description: "Reserve your scooter through our easy-to-use booking system." },
      { title: "Enjoy Your Ride", description: "Pick up your scooter and explore the city!" }
    ],

    featuredScootersTitle: "Featured Scooters",
    rentNow: "Rent Now",

    contactHomeTitle: "Get in Touch with Us Today",
    contactHomeDescription: "Whether you have questions about our services, need assistance with a booking, or want to provide feedback, our team is ready to assist you.",

    
  },
  GR: {
    // Home Page
    heroTitle: "Ανακαλύψτε τις Καλύτερες Ενοικιάσεις Σκούτερ",
    heroDescription: "Νοικιάστε ένα σκούτερ και εξερευνήστε το νησί με άνεση και στυλ.",
    heroRentNow: "Κάντε Κράτηση Τώρα",
    
    howItWorksTitle: "Πώς Λειτουργεί",
    howItWorksSteps: [
      { title: "Επιλέξτε ένα Σκούτερ", description: "Περιηγηθείτε στην επιλογή μας από σκούτερ και επιλέξτε το κατάλληλο για εσάς." },
      { title: "Κράτηση Online", description: "Κάντε κράτηση του σκούτερ σας μέσω του εύχρηστου συστήματός μας." },
      { title: "Απολαύστε τη Βόλτα Σας", description: "Παραλάβετε το σκούτερ σας και εξερευνήστε την πόλη!" }
    ],

    featuredScootersTitle: "Επιλεγμένα Σκούτερ",
    rentNow: "Κάντε Κράτηση Τώρα",

    contactHomeTitle: "Επικοινωνήστε Μαζί Μας Σήμερα",
    contactHomeDescription: "Είμαστε εδώ για να σας βοηθήσουμε με οποιαδήποτε ερώτηση, κράτηση ή σχόλιο.",
    contactHomePhoneLabel: "Αριθμοί Τηλεφώνου",
    contactHomeEmailLabel: "Ηλεκτρονική Διεύθυνση",
  },
  IT: {
    heroTitle: "Scopri i Migliori Noleggi di Scooter",
    heroDescription: "Noleggia uno scooter e esplora l'isola con facilità e stile.",
    heroRentNow: "Prenota Ora",
    
    howItWorksTitle: "Come Funziona",
    howItWorksSteps: [
      { title: "Scegli uno Scooter", description: "Sfoglia la nostra selezione di scooter e scegli quello che fa per te." },
      { title: "Prenota Online", description: "Prenota il tuo scooter tramite il nostro sistema di prenotazione facile da usare." },
      { title: "Goditi il ​​tuo Viaggio", description: "Ritira il tuo scooter ed esplora la città!" }
    ],

    featuredScootersTitle: "Scooter In Primo Piano",
    rentNow: "Prenota Ora",

    contactHomeTitle: "Contattaci Oggi",
    contactHomeDescription: "Siamo qui per aiutarti con qualsiasi domanda, prenotazione o feedback.",
    contactHomePhoneLabel: "Numeri di Telefono",
    contactHomeEmailLabel: "Indirizzo Email",
  },
  GER: {
    heroTitle: "Entdecken Sie die besten Scooter-Verleihangebote",
    heroDescription: "Mieten Sie einen Scooter und erkunden Sie die Insel stilvoll und mühelos.",
    heroRentNow: "Jetzt Mieten",
    
    howItWorksTitle: "Wie es funktioniert",
    howItWorksSteps: [
      { title: "Wählen Sie einen Scooter", description: "Stöbern Sie durch unsere Scooter-Auswahl und wählen Sie den passenden aus." },
      { title: "Online Buchen", description: "Reservieren Sie Ihren Scooter mit unserem benutzerfreundlichen Buchungssystem." },
      { title: "Genießen Sie Ihre Fahrt", description: "Holen Sie Ihren Scooter ab und erkunden Sie die Stadt!" }
    ],

    featuredScootersTitle: "Ausgewählte Scooter",
    rentNow: "Jetzt Mieten",

    contactHomeTitle: "Kontaktieren Sie uns noch heute",
    contactHomeDescription: "Wir sind hier, um Ihnen bei Fragen, Buchungen oder Feedback zu helfen.",
    contactPhoneLabel: "Telefonnummern",
    contactEmailLabel: "E-Mail Adresse",
    contactFacebookLabel: "",
    contactInstagramLabel: "Smart Ride",
  },
};
