import "./HowItWorks.css";
import img1 from "../../../../images/home/scouter1.jpg";
import img2 from "../../../../images/home/scouter2.jpg";
import img3 from "../../../../images/home/scouters.jpg";
import { useLanguage } from "../../../../contexts/LanguageContext";
import { translations } from "../translations";

const HowItWorks = () => {
  const { language } = useLanguage();
  const content = translations[language];

  return (
    <section className="how-it-works">
      <h2>{content.howItWorksTitle}</h2>
      <div className="steps">
        {content.howItWorksSteps.map((step, index) => (
          <div className="step" key={index}>
            <img src={index === 0 ? img1 : index === 1 ? img2 : img3} alt={`Step ${index + 1}`} />
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HowItWorks;
