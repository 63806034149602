import { Link } from "react-router-dom";
import "./Hero.css";
import { useLanguage } from "../../../../contexts/LanguageContext";
import { translations } from "../translations";

const Hero = () => {
  const { language } = useLanguage();
  const content = translations[language];

  return (
    <section className="hero">
      <div className="hero-background"></div>
      <div className="hero-content">
        <h1>{content.heroTitle}</h1>
        <p>{content.heroDescription}</p>
        <Link to={"./scooter-reservation-form"} className="button">{content.heroRentNow}</Link>
      </div>
    </section>
  );
};

export default Hero;
