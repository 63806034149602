import React from "react";
import Slider from "react-slick";
import "./ScooterPreview.css";
import { Link } from "react-router-dom";

const ScooterPreview = ({ scooter }) => {
  const settings = {
    customPaging: function (i) {
      return (
        <a href="#!">
          <img src={scooter.images[i ]} alt={`Thumbnail ${i + 2}`} />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  return (
    <div className="scooter-preview">
      <div className="slider-container">
        <Slider {...settings}>
          {scooter.images.map((image, index) => (
            <div key={index}>
              <img
                src={image}
                alt={`${scooter.title} preview ${index + 1}`}
              />
            </div>
          ))}
        </Slider>
      </div>
      <h3>{scooter.title}</h3>
      <Link to={"/scooter-reservation-form"} className="button">Rent Now</Link>
    </div>
  );
};

export default ScooterPreview;
