import React from "react";
import "./TermAndConditions.css";

import { useLanguage } from '../../../contexts/LanguageContext';
import { translations } from './translations';  // Import translations

const TermAndConditions = () => {
  const { language } = useLanguage();  // Get the selected language
  const content = translations[language];  // Get the content for the selected language

  console.log(content.title)

  return (
    <div className="terms-and-conditions">
      <div className="container">
        <h1 className="terms-title">{content.title}</h1>
        <ul className="terms-list">
          <li>{content.age_requirement}</li>
          <li>{content.booking_length}</li>
          <li>{content.helmets}</li>
          <li>{content.police_fines}</li>
          <li>{content.insurance_assistance}</li>
          <li>{content.accident_damage}</li>
          <li>{content.fuel_return}</li>
          <li>{content.deposit}</li>
          <li>{content.unlimited_mileage}</li>
          <li>{content.additional_driver}</li>
          <li>{content.rates}</li>
          <li>{content.after_hours}</li>
        </ul>
      </div>
    </div>
  );
};

export default TermAndConditions;
