import React from 'react';
import './MeetOurTeamSection.css';
import ceo from "../../../../images/aboutimages/images.jpeg";
import { useLanguage } from '../../../../contexts/LanguageContext'; // Import the language context
import { translations } from '../translations'; // Import the translations

const MeetOurTeamSection = () => {
  const { language } = useLanguage(); // Get the current language
  const content = translations[language]; // Fetch the appropriate translations

  return (
    <div className="meet-our-team">
      <div className="team-member">
        <div className="team-member-image">
          <img src={ceo} alt={content.teamOwnerName} />
        </div>
        <div className="team-member-content">
          <h3>{content.teamOwnerName}</h3>
          <h4>{content.teamOwnerTitle}</h4>
          <p>{content.teamOwnerDescription}</p>
        </div>
      </div>

      <div className="team-member reverse">
        <div className="team-member-content">
          <h3>{content.teamCEOName}</h3>
          <h4>{content.teamCEOTitle}</h4>
          <p>{content.teamCEODescription}</p>
        </div>
        <div className="team-member-image">
          <img src={ceo} alt={content.teamCEOName} />
        </div>
      </div>
    </div>
  );
};

export default MeetOurTeamSection;
