import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import { translations } from '../translations';
import "./InstructionsPreview.css";

const InstructionsPreview = () => {
  const { language } = useLanguage();
  const content = translations[language]; // Get the content based on the selected language

  return (
    <div className="instructions-section">
      <h3>{content.instructionsTitle}</h3>
      <p>{content.instructionsDescription}</p>
      <ul>
        <li>
          <div className="step1-background"></div>
          <div className="number-count">
            <p>1</p>
            <p className="content-step">{content.step1}</p>
          </div>
        </li>
        <li>
          <div className="step1-background"></div>
          <div className="number-count">
            <p>2</p>
            <p className="content-step">{content.step2}</p>
          </div>
        </li>
        <li>
          <div className="step1-background"></div>
          <div className="number-count">
            <p>3</p>
            <p className="content-step">{content.step3}</p>
          </div>
        </li>
        <li>
          <div className="step1-background"></div>
          <div className="number-count">
            <p>4</p>
            <p className="content-step">{content.step4}</p>
          </div>
        </li>
        <li>
          <div className="step1-background"></div>
          <div className="number-count">
            <p>5</p>
            <p className="content-step">{content.step5}</p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default InstructionsPreview;
