export const translations = {
  EN: {
    contactUsTitle: "CONTACT US",
    contactUsMobile: "Mobile Numbers",
    contactUsEmail: "Email Address",
    freeServicesTitle: "FREE SERVICES",
    freeServicesList: ["Map Of Island", "Helmets", "Unlimited Mileage", "Support At All Times (damage, fuel, etc.)"],
    whyUsTitle: "WHY US",
    whyUsList: ["Safe Vehicles", "New Models", "Brand New Tyres", "Affordable Prices", "Including Free Extras", "Friendly Staff And Support"],
    footerCopyright: "© 2024 Smart Ride. All rights reserved.",
  },
  GR: {
    contactUsTitle: "ΕΠΙΚΟΙΝΩΝΗΣΤΕ ΜΑΖΙ ΜΑΣ",
    contactUsMobile: "Αριθμοί Κινητών",
    contactUsEmail: "Ηλεκτρονική Διεύθυνση",
    freeServicesTitle: "ΔΩΡΕΑΝ ΥΠΗΡΕΣΙΕΣ",
    freeServicesList: ["Χάρτης του νησιού", "Κράνη", "Απεριόριστα Χιλιόμετρα", "Υποστήριξη Ανά Πάσα Στιγμή (ζημιά, καύσιμα, κ.λπ.)"],
    whyUsTitle: "ΓΙΑΤΙ ΕΜΑΣ",
    whyUsList: ["Ασφαλή Οχήματα", "Νέα Μοντέλα", "Καινούργια Ελαστικά", "Προσιτές Τιμές", "Συμπεριλαμβάνονται Δωρεάν Επιπλέοντα", "Φιλικό Προσωπικό και Υποστήριξη"],
    footerCopyright: "© 2024 Smart Ride. Όλα τα δικαιώματα διατηρούνται.",
  },
  IT: {
    contactUsTitle: "CONTATTACI",
    contactUsMobile: "Numeri di Telefono",
    contactUsEmail: "Indirizzo Email",
    freeServicesTitle: "SERVIZI GRATUITI",
    freeServicesList: ["Mappa dell'Isola", "Caschi", "Chilometraggio Illimitato", "Supporto in Ogni Momento (danni, carburante, ecc.)"],
    whyUsTitle: "PERCHÉ NOI",
    whyUsList: ["Veicoli Sicuri", "Modelli Nuovi", "Pneumatici Nuovi", "Prezzi Convenienti", "Extra Gratuiti Inclusi", "Personale Amichevole e Supporto"],
    footerCopyright: "© 2024 Smart Ride. Tutti i diritti riservati.",
  },
  GER: {
    contactUsTitle: "KONTAKTIEREN SIE UNS",
    contactUsMobile: "Mobiltelefonnummern",
    contactUsEmail: "E-Mail Adresse",
    freeServicesTitle: "KOSTENLOSE DIENSTLEISTUNGEN",
    freeServicesList: ["Inselkarte", "Helme", "Unbegrenzte Kilometer", "Rund-um-die-Uhr Unterstützung (Schäden, Treibstoff usw.)"],
    whyUsTitle: "WARUM WIR",
    whyUsList: ["Sichere Fahrzeuge", "Neue Modelle", "Neue Reifen", "Erschwingliche Preise", "Inklusive kostenlose Extras", "Freundliches Personal und Unterstützung"],
    footerCopyright: "© 2024 Smart Ride. Alle Rechte vorbehalten.",
  },
};