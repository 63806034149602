export const langNavMenuEN = [
  "Home",
  "Our Scooters",
  "About Us",
  "Terms And Conditions",
  "Contact Us"
]
export const langNavMenuGR = [
  "Αρχική",
  "Ο Στολος Μας",
  "Σχετικά με εμάς",
  "Όροι και Προϋποθέσεις",
  "Επικοινωνία"
]
export const langNavMenuIT = [
  "Home",
  "I Nostri Scooter",
  "Chi Siamo",
  "Termini e Condizioni",
  "Contattaci"
]
export const langNavMenuGER = [
  "Startseite",
  "Unsere Roller",
  "Über uns",
  "Allgemeine Geschäftsbedingungen (AGB)",
  "Kontaktieren Sie uns"
]