import React from 'react';
import './BestRentalExperience.css';
import img from "../../../../images/aboutimages/3.jpg";
import { useLanguage } from '../../../../contexts/LanguageContext'; // Import the language context
import { translations } from './translations'; // Import the translations

const BestRentalExperience = () => {
  const { language } = useLanguage(); // Get the current language
  const content = translations[language]; // Fetch the appropriate translations

  return (
    <section className="rental-experience">
      <div className="content-container">
        <div className="image-section">
          <img src={img} alt="Woman on a scooter" />
        </div>
        <div className="text-section">
          <h2>{content.bestRentalTitle}</h2>
          <p>{content.bestRentalText1}</p>
          <p>{content.bestRentalText2}</p>
        </div>
      </div>
      <div className="services-section">
        <div className="service-card">
          <h4>{content.premiumRentalServices}</h4>
          <ul>
            {content.servicesList.map((service, index) => (
              <li key={index}>{service}</li>
            ))}
          </ul>
        </div>
        <div className="service-card">
          <h4>{content.conditions}</h4>
          <ul>
            {content.conditionsList.map((condition, index) => (
              <li key={index}>{condition}</li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default BestRentalExperience;
