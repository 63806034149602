// src/components/greetingmessage/GreetingMessage.js
import React from 'react';
import './GreetingMessage.css';

const GreetingMessage = ({ content }) => {
  return (
    <>
      <h1 className="title">{content.greetingTitle}</h1>
      <p className='quote-para'>{content.greetingText}</p>
    </>
  );
};

export default GreetingMessage;
