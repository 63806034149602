import React from "react";
import Slider from "react-slick";
import "./FeaturedScooters.css";
import { scooterItems } from "./ScooterItems";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useLanguage } from "../../../../contexts/LanguageContext";
import { translations } from "../translations";

const FeaturedScooters = () => {

  const { language } = useLanguage();
  const content = translations[language];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <section className="featured-scooters">
      <h2 className="featured-scooters-title">Featured Scooters</h2>
      <div className="slider-container">
        <Slider {...settings}>
          {scooterItems.map((item) => (
            <div className="scooter" key={item.id}>
              <img src={item.img} alt={item.title} className="scooter-img"/>
              <div className="scooter-details">
                <h3>{item.title}</h3>
                <Link to={"./scooter-reservation-form"} className="button">
                 {content.rentNow}
                </Link>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

// Custom Arrow Components
const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        backgroundColor: "#0c004f",
        color: "#fff",
        borderRadius: "50%",
        left: "5px",
        zIndex: 2,
      }}
      onClick={onClick}
    />
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        backgroundColor: "#0c004f",
        color: "#fff",
        borderRadius: "50%",
        right: "5px",
        zIndex: 2,
      }}
      onClick={onClick}
    />
  );
};

export default FeaturedScooters;
