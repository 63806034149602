// src/translations.js
export const translations = {
  EN: {
    // Greeting Section
    greetingTitle: "Contact Us",
    greetingText: "We’re here to help you make the most of your scooter rental experience. Feel free to reach out to us using the form below, and we’ll get back to you as soon as possible.",
    
    // Contact Us Page
    contactTitle: "Get In Touch",
    contactText: "We'd love to hear from you! Reach out anytime—your journey with us starts with a conversation.",
    address: "123 Scooter St, San Francisco, CA",
    whatsapp: "+30 - 6984260513",
    viber: "+30  - 6978218939",
    emailTitle: "E-mail",
    email: "skiathos.smartride@gmail.com",
    followUs: "Follow Us",
    
    // form section 
    formTitle: "Send Us A Message",
    nameLabel: "Name",
    phoneLabel: "Phone",
    emailLabel: "Email",
    messageLabel: "Message",
    submitButton: "Submit",
    formSuccess: "Thank you for contacting us!",
    formFailure: "Submission failed. Please try again.",

    // Our Story Section
    ourStoryTitle: "OUR SHORT STORY",
    ourStorySubtitle: "Professionals dedicated to Rentals",
    ourStoryText1: "We are a locally owned and operated business with a passion for providing our customers with top-quality scooter rentals and exceptional customer service.",
    ourStoryText2: "Our team is comprised of experienced professionals who are dedicated to ensuring that you have a safe and enjoyable ride while exploring the beautiful island of Skiathos.",
  },

  GR: {
    // Greeting Section
    greetingTitle: "Επικοινωνήστε μαζί μας",
    greetingText: "Είμαστε εδώ για να σας βοηθήσουμε να αξιοποιήσετε στο έπακρο την εμπειρία ενοικίασης σκούτερ. Χρησιμοποιήστε τη φόρμα παρακάτω για να επικοινωνήσετε μαζί μας και θα σας απαντήσουμε το συντομότερο δυνατό.",
    
    // Contact Us Page
    contactTitle: "Επικοινωνήστε μαζί μας",
    contactText: "Θα χαρούμε να σας ακούσουμε! Επικοινωνήστε μαζί μας οποιαδήποτε στιγμή—το ταξίδι σας ξεκινάει με μια συνομιλία.",
    address: "123 Scooter St, San Francisco, CA",
    whatsapp: "+30 - 6984260513",
    viber: "+30  - 6978218939",
    emailTitle: "Ηλεκτρονική Διευθηνση",
    email: "skiathos.smartride@gmail.com",
    followUs: "Ακολουθήστε μας",
    
    //  form section 
    formTitle: "Χρησιμοποιήστε την παρακάτω φόρμα",
    nameLabel: "Όνομα",
    phoneLabel: "Τηλέφωνο",
    emailLabel: "Ηλεκτρονική Διεύθυνση",
    messageLabel: "Μήνυμα",
    submitButton: "Υποβολή",
    formSuccess: "Ευχαριστούμε που επικοινωνήσατε μαζί μας!",
    formFailure: "Η υποβολή απέτυχε. Παρακαλούμε προσπαθήστε ξανά.",

    // Our Story Section
    ourStoryTitle: "Η ΣΥΝΤΟΜΗ ΙΣΤΟΡΙΑ ΜΑΣ",
    ourStorySubtitle: "Επαγγελματίες αφιερωμένοι στις ενοικιάσεις",
    ourStoryText1: "Είμαστε μια τοπική επιχείρηση με πάθος για την παροχή υψηλής ποιότητας σκούτερ και εξαιρετικής εξυπηρέτησης.",
    ourStoryText2: "Η ομάδα μας αποτελείται από έμπειρους επαγγελματίες που αφιερώνονται στο να εξασφαλίσουν ότι θα έχετε μια ασφαλή και απολαυστική διαδρομή στο όμορφο νησί της Σκιάθου.",
  },

  IT: {
    // Greeting Section
    greetingTitle: "Contattaci",
    greetingText: "Siamo qui per aiutarti a sfruttare al meglio la tua esperienza di noleggio scooter. Contattaci utilizzando il modulo qui sotto e ti risponderemo il prima possibile.",
    
    // Contact Us Page
    contactTitle: "Mettiti in contatto",
    contactText: "Ci piacerebbe sentirti! Contattaci in qualsiasi momento—il tuo viaggio inizia con una conversazione.",
    address: "123 Scooter St, San Francisco, CA",
    whatsapp: "+30 - 6984260513",
    viber: "+30  - 6978218939",
    emailTitle: "E-mail",
    email: "skiathos.smartride@gmail.com",
    followUs: "Seguici",

    //form section
    formTitle: "Inviaci un Messaggio",
    nameLabel: "Nome",
    phoneLabel: "Telefono",
    emailLabel: "Email",
    messageLabel: "Messaggio",
    submitButton: "Invia",
    formSuccess: "Grazie per averci contattato!",
    formFailure: "Invio fallito. Riprova.",

    // Our Story Section
    ourStoryTitle: "LA NOSTRA STORIA BREVE",
    ourStorySubtitle: "Professionisti dedicati al noleggio",
    ourStoryText1: "Siamo un'azienda a conduzione locale con la passione di fornire ai nostri clienti scooter di alta qualità e un servizio clienti eccezionale.",
    ourStoryText2: "Il nostro team è composto da professionisti esperti che si dedicano a garantire un'esperienza sicura e piacevole durante la vostra esplorazione dell'isola di Skiathos.",
  },

  GER: {
    // Greeting Section
    greetingTitle: "Kontaktieren Sie uns",
    greetingText: "Wir sind hier, um Ihnen zu helfen, das Beste aus Ihrer Roller-Miet-Erfahrung herauszuholen. Verwenden Sie das untenstehende Formular, um uns zu kontaktieren, und wir werden Ihnen so schnell wie möglich antworten.",
    
    // Contact Us Page
    contactTitle: "Kontakt aufnehmen",
    contactText: "Wir würden uns freuen, von Ihnen zu hören! Kontaktieren Sie uns jederzeit—Ihre Reise beginnt mit einem Gespräch.",
    address: "123 Scooter St, San Francisco, CA",
    whatsapp: "+30 - 6984260513",
    viber: "+30  - 6978218939",
    emailTitle: "E-mail",
    email: "skiathos.smartride@gmail.com",
    followUs: "Folge uns",

    // form section
    formTitle: "Senden Sie uns eine Nachricht",
    nameLabel: "Name",
    phoneLabel: "Telefon",
    emailLabel: "E-Mail",
    messageLabel: "Nachricht",
    submitButton: "Senden",
    formSuccess: "Vielen Dank für Ihre Kontaktaufnahme!",
    formFailure: "Übermittlung fehlgeschlagen. Bitte versuchen Sie es erneut.",
    
    // Our Story Section
    ourStoryTitle: "UNSERE KURZE GESCHICHTE",
    ourStorySubtitle: "Fachleute, die sich dem Verleih widmen",
    ourStoryText1: "Wir sind ein lokal geführtes Unternehmen mit der Leidenschaft, unseren Kunden hochwertige Roller und einen exzellenten Kundenservice zu bieten.",
    ourStoryText2: "Unser Team besteht aus erfahrenen Fachleuten, die sich darauf konzentrieren, Ihnen ein sicheres und angenehmes Erlebnis zu ermöglichen, während Sie die schöne Insel Skiathos erkunden.",
  },
};
