import bike from "../../../images/scooterrental/cf450/1.jpg";

const scooterContent = [
  {
    title: "Cforce atv 450cc",
    images: [bike, bike, bike, bike, bike],
  },
  {
    title: "Sym sr 125cc",
    images: [bike, bike, bike, bike, bike],
  },
  {
    title: "Sym st 200cc",
    images: [bike, bike, bike, bike, bike],
  },
  {
    title: "Cforce atv 450cc long",
    images: [bike, bike, bike, bike, bike],
  }
];

export default scooterContent;
