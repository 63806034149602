import React, { useState } from 'react';
import './ScooterReservationForm.css';

const ScooterReservationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    startDateTime: '',
    endDateTime: '',
    hasLicense: false,
    selectedScooter: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validate = () => {
    let newErrors = {};

    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.mobile) newErrors.mobile = 'Mobile number is required';
    if (!formData.startDateTime) newErrors.startDateTime = 'Start date and time are required';
    if (!formData.endDateTime) newErrors.endDateTime = 'End date and time are required';
    if (!formData.hasLicense) newErrors.hasLicense = 'You must have a motorcycle license';
    if (!formData.selectedScooter) newErrors.selectedScooter = 'Please select a scooter';

    // Ensure end datetime is after start datetime
    if (formData.startDateTime && formData.endDateTime) {
      const startDateTime = new Date(formData.startDateTime);
      const endDateTime = new Date(formData.endDateTime);
      if (endDateTime <= startDateTime) {
        newErrors.endDateTime = 'End date/time must be after start date/time';
      }
    }

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // Handle successful form submission
      console.log('Form submitted', formData);
      // Reset form
      setFormData({
        name: '',
        email: '',
        mobile: '',
        startDateTime: '',
        endDateTime: '',
        hasLicense: false,
        selectedScooter: ''
      });
      setErrors({});
    }
  };

  // Get the current datetime for min attribute
  const currentDateTime = new Date().toISOString().slice(0, 16);

  return (
    <div className='scooter-reservation-form'>
      <div className='background-image'></div>
      <form className="reservation-form" onSubmit={handleSubmit}>
      <h2>Scooter Reservation</h2>

      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        {errors.name && <span className="error">{errors.name}</span>}
      </div>

      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <span className="error">{errors.email}</span>}
      </div>

      <div className="form-group">
        <label>Mobile Number</label>
        <input
          type="tel"
          name="mobile"
          value={formData.mobile}
          onChange={handleChange}
        />
        {errors.mobile && <span className="error">{errors.mobile}</span>}
      </div>

      <div className="form-group">
        <label>Rent From</label>
        <input
          type="datetime-local"
          name="startDateTime"
          value={formData.startDateTime}
          onChange={handleChange}
          min={currentDateTime} // Prevent past date selection
        />
        {errors.startDateTime && <span className="error">{errors.startDateTime}</span>}
      </div>

      <div className="form-group">
        <label>Rent Until</label>
        <input
          type="datetime-local"
          name="endDateTime"
          value={formData.endDateTime}
          onChange={handleChange}
          min={formData.startDateTime || currentDateTime} // Prevent end date/time before start date/time
        />
        {errors.endDateTime && <span className="error">{errors.endDateTime}</span>}
      </div>

      <div className="form-group">
        <label>
          <input
            type="checkbox"
            name="hasLicense"
            checked={formData.hasLicense}
            onChange={handleChange}
          />
          I own a motorcycle license
        </label>
        {errors.hasLicense && <span className="error">{errors.hasLicense}</span>}
      </div>

      <div className="form-group">
        <label>Select A Scooter</label>
        <select
          name="selectedScooter"
          value={formData.selectedScooter}
          onChange={handleChange}
        >
          <option value="">-- Select a Scooter --</option>
          <option value="Sym symphony 125cc">Sym symphony 125cc</option>
          <option value="Sym symphony 200cc">Sym symphony 200cc</option>
          <option value="Atv/Quad 450 cc">Atv/Quad 450 cc</option>
          <option value="Atv/Quad 450 Long">Atv/Quad 450 Long</option>
        </select>
        {errors.selectedScooter && <span className="error">{errors.selectedScooter}</span>}
      </div>

      <button type="submit">Reserve Now</button>
    </form>
    </div>
  );
};

export default ScooterReservationForm;
