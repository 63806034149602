import React, { useState } from 'react';
import './ContactForm.css';

import { useLanguage } from '../../../../contexts/LanguageContext';  // Import the language context
import { translations } from '../../../../translations';  // Import the translations

const ContactForm = () => {
  const { language } = useLanguage();  // Get the selected language from context
  const content = translations[language];  // Get the content for the selected language

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  });

  const [formStatus, setFormStatus] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormStatus('Submitting...');

    // Simulate form submission
    setTimeout(() => {
      const isSuccess = true;  // Simulate success condition
      if (isSuccess) {
        setFormStatus(content.formSuccess);  // Use translated success message
        setFormData({
          name: '',
          phone: '',
          email: '',
          message: ''
        });
      } else {
        setFormStatus(content.formFailure);  // Use translated failure message
      }
    }, 2000);
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <h2 className='form-title'>{content.formTitle}</h2>

        <div className="form-group">
          <label htmlFor="name">{content.nameLabel}:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="phone">{content.phoneLabel}:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">{content.emailLabel}:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="message">{content.messageLabel}:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            required
          ></textarea>
        </div>

        <button type="submit">{content.submitButton}</button>
        {formStatus && <p className="form-status">{formStatus}</p>}
      </form>
    </div>
  );
};

export default ContactForm;
