export const translations = {
  EN: {
    aboutUsTitle: "About Us",
    meetOurTeamTitle: "Meet Our Team",
    readyToRideTitle: "Ready to Ride?",
    bookScooterButton: "Book a Scooter Now",
    
    // OurStorySection translations
    ourShortStory: "OUR SHORT STORY",
    professionalsDedicated: "Professionals dedicated to Rentals",
    storyText1: "We are a locally owned and operated business with a passion for providing our customers with top-quality scooter rentals and exceptional customer service.",
    storyText2: "Our team is comprised of experienced professionals who are dedicated to ensuring that you have a safe and enjoyable ride while exploring the beautiful island of Skiathos.",

    // MeetOurTeamSection translations
    teamOwnerName: "Lorenc Hima",
    teamOwnerTitle: "Owner & Founder",
    teamOwnerDescription: "Lorenc has over 20 years of experience in the scooter rental industry. His passion for providing exceptional service has driven the success of our company since day one.",
    
    teamCEOName: "Maria Stergiou",
    teamCEOTitle: "CEO",
    teamCEODescription: "Maria brings a wealth of knowledge in business management and customer relations, ensuring that our services remain top-notch and that our customers are always satisfied.",
  },
  GR: {
    aboutUsTitle: "Σχετικά με εμάς",
    meetOurTeamTitle: "Γνωρίστε την Ομάδα μας",
    readyToRideTitle: "Έτοιμοι για Βόλτα;",
    bookScooterButton: "Κλείστε ένα Σκούτερ τώρα",

    ourShortStory: "Η ΣΥΝΤΟΜΗ ΙΣΤΟΡΙΑ ΜΑΣ",
    professionalsDedicated: "Επαγγελματίες αφιερωμένοι στις ενοικιάσεις",
    storyText1: "Είμαστε μια τοπική επιχείρηση με πάθος για την παροχή σκούτερ υψηλής ποιότητας και εξαιρετικής εξυπηρέτησης πελατών.",
    storyText2: "Η ομάδα μας αποτελείται από έμπειρους επαγγελματίες που είναι αφοσιωμένοι στην παροχή μιας ασφαλούς και ευχάριστης διαδρομής στο όμορφο νησί της Σκιάθου.",

    teamOwnerName: "Λορέντς Χίμα",
    teamOwnerTitle: "Ιδιοκτήτης & Ιδρυτής",
    teamOwnerDescription: "Ο Λορέντς έχει πάνω από 20 χρόνια εμπειρίας στον κλάδο των ενοικιάσεων σκούτερ. Το πάθος του για εξαιρετική εξυπηρέτηση είναι το κλειδί της επιτυχίας μας από την αρχή.",

    teamCEOName: "Μαρία Στεργίου",
    teamCEOTitle: "Διευθύνουσα Σύμβουλος",
    teamCEODescription: "Η Μαρία διαθέτει μεγάλη εμπειρία στη διοίκηση επιχειρήσεων και στις σχέσεις με τους πελάτες, εξασφαλίζοντας ότι οι υπηρεσίες μας παραμένουν κορυφαίες.",
  },
  IT: {
    aboutUsTitle: "Chi Siamo",
    meetOurTeamTitle: "Conosci il Nostro Team",
    readyToRideTitle: "Pronti per un Giro?",
    bookScooterButton: "Prenota uno Scooter Ora",

    ourShortStory: "LA NOSTRA STORIA BREVE",
    professionalsDedicated: "Professionisti dedicati al noleggio",
    storyText1: "Siamo un'azienda locale con la passione per fornire ai nostri clienti scooter di alta qualità e un servizio clienti eccezionale.",
    storyText2: "Il nostro team è composto da professionisti esperti che sono dedicati a garantire che tu abbia un'esperienza sicura e piacevole mentre esplori la splendida isola di Skiathos.",

    teamOwnerName: "Lorenc Hima",
    teamOwnerTitle: "Proprietario e Fondatore",
    teamOwnerDescription: "Lorenc ha oltre 20 anni di esperienza nell'industria del noleggio di scooter. La sua passione per un servizio eccezionale ha guidato il successo della nostra azienda sin dal primo giorno.",

    teamCEOName: "Maria Stergiou",
    teamCEOTitle: "CEO",
    teamCEODescription: "Maria porta una vasta conoscenza nella gestione aziendale e nelle relazioni con i clienti, garantendo che i nostri servizi rimangano di altissima qualità e che i nostri clienti siano sempre soddisfatti.",
  },
  GER: {
    aboutUsTitle: "Über Uns",
    meetOurTeamTitle: "Lernen Sie Unser Team kennen",
    readyToRideTitle: "Bereit für die Fahrt?",
    bookScooterButton: "Jetzt ein Roller buchen",

    ourShortStory: "UNSERE KURZE GESCHICHTE",
    professionalsDedicated: "Fachleute, die sich dem Verleih widmen",
    storyText1: "Wir sind ein lokal geführtes Unternehmen mit Leidenschaft für hochwertige Roller und exzellenten Kundenservice.",
    storyText2: "Unser Team besteht aus erfahrenen Fachleuten, die sich dafür einsetzen, dass Sie eine sichere und angenehme Fahrt auf der wunderschönen Insel Skiathos genießen.",

    teamOwnerName: "Lorenc Hima",
    teamOwnerTitle: "Eigentümer und Gründer",
    teamOwnerDescription: "Lorenc hat über 20 Jahre Erfahrung in der Roller-Vermietungsbranche. Seine Leidenschaft für exzellenten Service ist der Schlüssel zu unserem Erfolg seit dem ersten Tag.",

    teamCEOName: "Maria Stergiou",
    teamCEOTitle: "Geschäftsführerin",
    teamCEODescription: "Maria bringt umfangreiche Kenntnisse im Bereich Unternehmensführung und Kundenbeziehungen mit und stellt sicher, dass unsere Dienstleistungen von höchster Qualität sind und unsere Kunden stets zufrieden sind.",
  }
};
