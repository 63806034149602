import React from 'react';
import './ContactusFooterSection.css';
import { useLanguage } from '../../../contexts/LanguageContext';
import { translations } from '../translations';

const ContactusFooterSection = () => {
  const { language } = useLanguage();
  const content = translations[language];

  return (
    <div className="contact-us-footer-section">
      <h3>{content.contactUsTitle}</h3>
      <ul>
        <li>
          <p className="name">{content.contactUsMobile}:</p>
          <div className="items">
            <p>+30 - 6984260513</p>
            <p>+30 - 6978218939</p>
          </div>
        </li>
        <li>
          <p className="name">{content.contactUsEmail}:</p>
          <div className="items">
            <p>skiathos.smartride@gmail.com</p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ContactusFooterSection;
