import "./SocialMediaFooterSection.css"

const SocialMediaFooterSection = () =>{
  return(
    <div className="social-media-footer-section">
      <p className="white-background left"></p>
      <a href="https://www.facebook.com/profile.php?id=61555051207260&locale=el_GR"><i className="facebook"></i></a>
      <a href="https://www.instagram.com/smart_ride_rentals/"><i className="instagram"></i></a>
      <p className="white-background right"></p>
    </div>
  )
}

export default SocialMediaFooterSection;