import React from 'react';
import './ContactInformations.css';

import locationIcon from "../../../../images/contactus/location.svg";
import emailIcon from "../../../../images/contactus/email.svg";
import phoneIcon1 from "../../../../images/contactus/whatsapp.svg";
import phoneIcon2 from "../../../../images/contactus/viber.svg";

import { useLanguage } from '../../../../contexts/LanguageContext';  // Import language context
import { translations } from '../../../../translations';  // Import translations

const ContactInformations = () => {
  const { language } = useLanguage();  // Get the selected language from the context
  const content = translations[language];  // Get the translated content for the selected language

  return (
    <div className="contact-info">
      <div className='text-of-contact-info'>
        <h2>{content.contactTitle}</h2>
        <p className='contact-info-para'>{content.contactText}</p>
        <hr />
      </div>

      <div className='contact-info-pairs-section'>
        <div className='contact-info-pairs'>
          <img src={locationIcon} alt="Location Icon" />
          <div className='pairs-text'>
            <h3>{content.addressTitle}</h3>
            <p>{content.address}</p>
          </div>
        </div>

        <div className='contact-info-pairs'>
          <img src={phoneIcon1} alt="Phone Icon 1" />
          <div className='pairs-text'>
            <h3>Whatsapp</h3>
            <p>{content.whatsapp}</p>
            <p>{content.viber}</p>
          </div>
        </div>

        <div className='contact-info-pairs'>
          <img src={phoneIcon2} alt="Phone Icon 2" />
          <div className='pairs-text'>
            <h3>Viber</h3>
            <p>{content.viber}</p>
            <p>{content.whatsapp}</p>

          </div>
        </div>

        <div className='contact-info-pairs'>
          <img src={emailIcon} alt="Email Icon" />
          <div className='pairs-text'>
            <h3>{content.emailTitle}</h3>
            <p>{content.email}</p>
          </div>
        </div>
      </div>

      <div className='social-media-section'>
        <hr />
        <h3>{content.followUs}</h3>
        <div className='social-icons'>
          <a href="https://www.facebook.com/profile.php?id=61555051207260&locale=el_GR"><span className='facebook'></span></a>
          <a href="https://www.instagram.com/smart_ride_rentals/"><span className='instagram'></span></a>
        </div>
      </div>
    </div>
  );
};

export default ContactInformations;
