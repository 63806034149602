import React from 'react';
import './FreeServicesFooterSection.css';
import { useLanguage } from '../../../contexts/LanguageContext';
import { translations } from '../translations';

const FreeServicesFooterSection = () => {
  const { language } = useLanguage();
  const content = translations[language];

  return (
    <div className="free-services-footer-section">
      <h3>{content.freeServicesTitle}</h3>
      <ul>
        {content.freeServicesList.map((service, index) => (
          <li key={index}>{service}</li>
        ))}
      </ul>
    </div>
  );
};

export default FreeServicesFooterSection;
