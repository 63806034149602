import React from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';
import { translations } from '../translations';
import './ContactHome.css';

import phone from '../../../../images/home/phone.png';
import email from '../../../../images/home/email.png';
import facebook from '../../../../images/home/facebook.png';
import instagram from '../../../../images/home/instagram.png';

const ContactHome = () => {
  const { language } = useLanguage();
  const content = translations[language];  // Get the content for the selected language

  return (
    <section className="contact-section">
      <h2 className="contact-section-title">{content.contactHomeTitle}</h2>
      <p className="contact-section-para">{content.contactHomeDescription}</p>
      <div className="contact-methods">
        <div className="method">
          <img src={phone} alt="phone" />
          <div className="numbers">
            <p>+30 - 6984260513</p>
            <p>+30 - 6978218939</p>
          </div>
        </div>
        <div className="method">
          <img src={email} alt="email" />
          <h3>skiathos.smartride@gmail.com</h3>
        </div>
        <div className="method">
          <img src={facebook} alt="facebook" />
          <h3>Smart Ride</h3>
        </div>
        <div className="method">
          <img src={instagram} alt="instagram" />
          <h3>Smart Ride</h3>
        </div>
      </div>
    </section>
  );
};

export default ContactHome;
