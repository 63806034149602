import React from 'react';
import './WhyusFooterSection.css';
import { useLanguage } from '../../../contexts/LanguageContext';
import { translations } from '../translations';

const WhyusFooterSection = () => {
  const { language } = useLanguage();
  const content = translations[language];

  return (
    <div className="whyus-footer-section">
      <h3>{content.whyUsTitle}</h3>
      <ul>
        {content.whyUsList.map((reason, index) => (
          <li key={index}>{reason}</li>
        ))}
      </ul>
    </div>
  );
};

export default WhyusFooterSection;
